<template>
  <div class="alert alert-warning" role="alert">
    <p class="mb-1">合法用户名个数：{{ sucUsersNum }}</p>
    <p class="mb-1">
      非法用户名个数：{{ errorUsers.length }}
      <a v-show="errorUsers.length > 0"
        href="javascript:void(0)"
        class="btn btn-link btn-sm"
        role="button"
        aria-pressed="true"
        @click="errUserlist = !errUserlist">
        {{ !errUserlist ? '展开查看' : '收起列表' }}</a>
    </p>
    <p v-show="errUserlist"
      class="mb-1"
      v-for="user in errorUsers"
      :key="user">
      {{ user }}</p>
  </div>
</template>
<script>
export default {
  name: 'userlist',
  props: {
    sucUsersNum: Number,
    errorUsers: Array
  },
  data () {
    return {
      errUserlist: false
    }
  }
}
</script>
