<template>
	<div>
		<p class="h4 pt-4 pb-4">添加权限</p>
		<el-form
			ref="form1"
			:model="form1"
			:rules="rules1"
			label-width="80px"
			style="max-width: 500px;">
			<section v-if="!step">
				<el-form-item label="projectID" prop="projectid">
					<el-input v-model="form1.projectid"></el-input>
				</el-form-item>
				<el-form-item label="Username" prop="username">
					<el-input
						v-model="form1.username"
						type="textarea"
						:rows="5"
						placeholder="输入用户名或用户名前缀（以换行分割）"></el-input>
				</el-form-item>
				<user-list
					class="mb-5"
					style="margin-left: 80px"
					:sucUsersNum="successUsers.length"
					:errorUsers="errorUsers">
				</user-list>
				<el-form-item>
					<el-button type="primary" @click="usersRegular">合法检验</el-button>
					<el-button
						type="primary"
						@click="nextStep"
						v-show="successUsers.length && form1.projectid">
						下一步
					</el-button>
				</el-form-item>
			</section>
			<section v-show="step">
				<p class="mb-4">项目类型：{{project.status4QC === 7001 ? '质检任务' : '普通任务'}}</p>
				<el-form-item label="合法用户">
					<div class="alert alert-light border" role="alert">
						<p class="mb-0"
							style="line-height: 2"
							v-for="user in successUsers"
							:key="user">
							{{ user }}</p>
					</div>
				</el-form-item>
				<el-form-item label="权限" prop="puflag">
					<el-select
						v-model="form1.puflag"
						multiple
						filterable
						allow-create
						default-first-option
						placeholder="请选择权限(可多选)"
						style="width:100%">
						<el-option
							v-for="(item, index) in options"
							:key="item.value"
							:label="item.label"
							:value="item.value"
							:class="showStr[index] === '1' ? '' : 'd-none'">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="层级" prop="step" v-if="project.status4QC === 7001">
					<el-select
						v-model="form1.step"
						placeholder="请选择层级"
						style="width:100%">
						<el-option
							v-for="(item, index) in (project.maxLayer + 2)"
							:key="item"
							:label="index"
							:value="index">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="step = false">上一步</el-button>
					<el-button type="primary" v-loading="changepuloading" @click="changepu">更新</el-button>
				</el-form-item>
			</section>
		</el-form>
	</div>
</template>

<script>
import userList from './components/userslist'
import { tuflagArr } from './tuflag'
import { projectData, addPuflag } from '@/api/limit'

export default {
  name: 'app',
  components: {
    userList
  },
  data () {
    return {
      step: false,
      show: 4095,
      changepuloading: '',
      project: {},
      // 下拉框选项
      options: tuflagArr,
      errorUsers: [],
      successUsers: [],
      form1: {
        username: '',
        projectid: '',
        puflag: [],
        step: 0
      },
      rules1: {
        username: [{ required: '请输入用户名' }],
        projectid: [{ required: '请输入项目ID' }],
        puflag: [{ required: '请选择权限' }],
        step: [{ required: '请选择层级' }]
      }
    }
  },
  computed: {
    // 返回二进制字符串，长度不满12位在前补齐
    showStr () {
      const vm = this
      const BINARY = vm.show.toString(2)
      const num = 12 - BINARY.length
      let result = ''
      if (num === 0) {
        result = BINARY
      } else {
        let zero = ''
        for (let i = 0; i < num; i++) {
          zero = zero + '0'
        }
        result = zero + BINARY
      }
      return result
    },
    // 计算this.form1.puflag
    puflagResult () {
      let sum = 0
      this.form1.puflag.forEach((value) => {
        sum = sum | value
      })
      return sum
    }
  },
  methods: {
    nextStep () {
      projectData(parseInt(this.form1.projectid)).then(
        res => {
          if (res.code === 0) {
            this.project = res.project
            this.step = true
            // 清除验证信息
            // this.$refs['form1'].resetFields()
          } else {
            this.$message({
              message: `失败：${res.message}`,
              type: 'error'
            })
          }
        }
      ).catch(
        err => {
          console.log(err)
        }
      )
    },
    /**
     * 检验合法用户
     */
    usersRegular () {
      if (this.form1.username) {
        this.form1.username = this.getArr(this.form1.username).join('\n')
      } else {
        this.$refs.form1.validate()
      }
    },
    /**
     * 用户名格式检验
     * @param {string} oldStr 用户输入的字符串
     */
    getArr (oldStr) {
      this.errorUsers = []
      this.successUsers = []
      // 用户名合法判断
      const reg1 = /^[a-zA-Z][a-zA-Z0-9_-]{4,29}$/
      const rule = val => reg1.test(val) && val.length >= 5 && val.length <= 30
      // 去掉头尾回车，替换回车，去掉全部空白符
      const str = oldStr.replace(/^\n+|\n+$/g, '').replace(/\n+/g, '*').replace(/\s+/g, '')
      // 单个用户和多个用户区分判断
      const usersArr = str.indexOf('*') === -1 ? [str] : str.split('*')
      // 用户合法性过滤
      const items = usersArr.filter((item) => {
        if (rule(item)) {
          return item
        } else {
          this.errorUsers.push(item)
        }
      })
      this.successUsers = items
      return items
    },
    getJson () {
      const vm = this
      const taskusers = []
      vm.successUsers.forEach(
        val => {
          const json = {
            username: val,
            projectid: parseInt(vm.form1.projectid),
            puflag: vm.puflagResult,
            step: vm.form1.step
          }
          taskusers.push(json)
        }
      )
      return {
        projectid: parseInt(vm.form1.projectid),
        taskusers: taskusers
      }
    },
    changepu () {
      const vm = this
      // vm.changepuloading = true
      this.$refs.form1.validate(valid => {
        if (valid) {
          const json = vm.getJson()
          addPuflag(json).then(
            rsp => {
              if (rsp.code === 0) {
                vm.changepuloading = false
                vm.$message({
                  message: '修改成功',
                  type: 'success'
                })
              } else {
                vm.changepuloading = false
                vm.$message({
                  message: rsp.message,
                  type: 'error'
                })
              }
            }
          ).catch(
            err => {
              console.log(err)
            }
          )
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style>
</style>
